<template>
  <div>
    <div class="content" ref="homeUl">
      <van-nav-bar :fixed="true" :title="title">
        <template #left>
          <img
            src="../../assets/img/return.png"
            class="navImg"
            @click="close"
          />
        </template>
      </van-nav-bar>
      <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <my-scroll :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore">
            <div
              class="div-content"
              v-for="item in feedList"
              :key="item[0].date"
            >
                <p class="time">{{ item[0].date }}</p>
                <div>
                <div class="info" v-for="dd in item" :key="dd.id">
                  <div class="info_left">
                    <p class="info_time">{{ dd.time }}</p>
                    <div class="displays">
                      <div class="box-list">
                      <span class="info_text" v-for="val in dd.feed" :key="val.id">
                        <span
                          v-if="
                            val.cate_id != 2 &&
                            val.cate_id != 1 &&
                            val.cate_id != 3
                          "
                        >
                          {{ val.name + ':' + val.weight + '克' }}
                        </span>
                        <span v-if="val.cate_id == 1">
                          {{ val.name + ':' + val.weight + '份' }}
                        </span>
                        <span v-if="val.cate_id == 2">
                          {{ val.name }}
                        </span>
                        <span v-if="val.cate_id == 3">
                          {{ val.name }}
                        </span>
                      </span>
                    </div>
                    <div
                                  class="clickviope"
                                  @click="clickviope(dd.video_url)"
                                  v-if="dd.video_url"
                                >
                                  喂养视频
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </my-scroll>
        </van-pull-refresh>
      </div>
    </div>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <van-dialog v-model="viopeshow" :show-confirm-button="false">
      <div class="pot">
        <div class="tc-viope-box">
          <div class="weiyang">喂养视频</div>
          <div class="bfvipoe">
            <video-player
              ref="myVideo"
              :playsinline="true"
              v-show="viopeshow"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </div>
        <img
          src="../../assets/img/close.png"
          alt=""
          class="close"
          @click="closevipoe"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { record } from '../../api/index'
import myScroll from '../components/myScroll.vue'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
export default {
  components: {
    myScroll,
    videoPlayer
  },
  data() {
    return {
      title: '喂养记录',
      feedList: [],
      page: 1,
      days: 9,
      enableLoadMore: true,
      titleShow: false,
      none: false,
      msg: '',
      isLoading: false,
      viopeshow: false,
      playerOptions: {
        autoplay: true,
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: '' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      }
    }
    
  },
  created() {
    this.recordJl()
    // console.log(this.$route.query.p_id, '获取的数据')
  },
  mounted() {},
  methods: {
    close() {
      this.$router.go(-1)
      // window.history.go(-1) 返回+刷新
      // window.history.back();       //返回
      // history.go(-1) 也是返回当前页的上一页，不过表单里的数据全部还在
    },
    //喂养记录
    async recordJl() {
      let data = {
        ls_id: this.$route.query.p_id,
        page: this.page,
        days: this.days
      }
      let res = await record(data)
      console.log(res)
      if (res.data.code == 200) {
        if (this.page == 1) {
          this.feedList = res.data.data
        } else {
          this.feedList = [...this.feedList, ...res.data.data]
        }
      }
      if (res.data.code == '4004') {
        this.enableLoadMore = false
      }
    },
    clickviope(e) {
      console.log(e, '视频')
      this.viopeshow = true
      this.playerOptions.sources[0].src = e
    },
    closevipoe() {
      this.viopeshow = false
      this.playerOptions.sources[0].src = ''
    },
    // 上拉加载
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) return
        this.page = this.page + 1
        this.recordJl()
        done()
      }, 200)
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false
        this.page = 1
        this.recordJl()
        this.enableLoadMore = true
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.box-list{
  width: 70%;
}
.van-nav-bar {
  width: 100%;
  height: 1.7rem;
  background: #19B47B;
  /deep/ .van-nav-bar__content {
    height: 100%;
    font-size: 0.5rem;
  }
  /deep/ .van-nav-bar__title {
    font-size: 0.55rem;
    font-weight: 700;
    color: #fff;
    height: 1.4rem;
    line-height: 1.4rem;
  }
}
.navImg {
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 0.3rem;
}
.div-content:last-child {
  padding-bottom: 0.4rem;
}
.content {
  min-height: 100vh;
  width: 100vw;
  // padding-top: 1.41rem;
  background: #FFFFEB;
  display: flex;
  flex-direction: column;
  align-items: center;
  .time {
    width: 100%;
    font-size: 0.6rem;
    font-weight: 600;
    color: #945614;
    padding-top: 0.3rem;
  }

  .clickviope {
      // width: 125px;
      width: 165px;
      height: 48px;
      background: #fbe5ba;
      border: 1px solid #945614;
      border-radius: 23px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      margin-left: 30px;
      margin-bottom: 20px;
      color: #945614;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
  .info {
    width: 9rem;
    // height: 2rem;
    background: #000;
    margin-top: 0.2rem;
    background: rgba(#FBF6D3, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.1rem;
    .info_left {
      width: 70%;
      height: 100%;
      .info_time {
        font-size: 0.35rem;
        color: #945614;
        padding-left: 0.3rem;
        padding-top: 0.15rem;
      }
      .displays {
        width: 8.4rem;
        display: flex;
        padding-left: 0.3rem;
      }
      .info_text {
        font-size: 0.4rem;
        color: #945614;
        padding-top: 0.1rem;
        font-weight: 600;
      }
      .info_text:not(:last-child)::after {
        content: '、';
      }
      .info_text:last-child {
        padding-bottom: 0.2rem;
      }
    }
  }
}
.van-pull-refresh {
  /deep/ .van-pull-refresh__track {
    margin-top: 1.4rem;
  }
}




.logoImg {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  img {
    width: 3.2rem;
    // height: 2.7rem;
    // width: 687.5px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
}
.lookUp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 1.1rem;
    margin-top: 0.5rem;
  }
}
.van-field {
  width: 6rem;
  height: 1rem;
  border-radius: 0.13rem;
  background: #ffcead;
  color: #945614;
  overflow: hidden;
  /deep/ .van-field__body {
    height: 100%;
    font-size: 0.4rem;
    color: #945614;
  }
  /deep/ .van-field__control {
    color: #945614;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 600;
  color: #945614;
}

// 弹出视频盒子
.van-dialog {
  width: 700px;
  height: 560px;
  // background-color: rgba(0, 0, 0, 0.7);
  // margin: 0 auto;
  background: none;
}
.pot {
  width: 700px;
  height: 560px;
  position: relative;
  // padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .tc-viope-box {
    // width: 562.5px;
    // height: 320px;
    // background-color: #fff;
    width: 630px;
    // width: 600px;
    height: 460px;
    background: #fbe5ba;
    border-radius: 20px;

    .weiyang {
      width: 630px;
      // width: 600px;
      height: 93px;
      background: #d79f6d;
      border-radius: 20px 20px 0 0;

      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #935513;
      text-align: center;
      line-height: 93px;
    }
    .bfvipoe {
      width: 540px;
      height: 320px;
      // background: #9fd09a;
      margin: 0 auto;
      margin-top: 23.5px;
      /deep/ #myplayer {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.close {
  width: 100px;
  height: 100px;
  position: absolute;
  // right: 20px;
  right: 0px;
  top: 20px;
  // right: -50px;
  // top: -50px;
  // z-index: 10000000 !important;
}
</style>
